import React, {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';

import './styles.scss';

export const Privacy: FunctionComponent = () => {
  return <div className="privacy">
    <header className="privacy__title-window">
      <FormattedMessage
        id={'gritx.privacy.titlePrivacy'}
        defaultMessage={'Privacy Policy'}
      />
    </header>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum1'}
        defaultMessage={'Lorem ipsum'}
      />
    </p>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum2'}
        defaultMessage={'Semper quis'}
      />
    </p>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum3'}
        defaultMessage={'Tellus rutrum'}
      />
    </p>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum4'}
        defaultMessage={'Vestibulum'}
      />
    </p>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum5'}
        defaultMessage={'Vel eros donec ac odio'}
      />
    </p>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum6'}
        defaultMessage={'Tempor nec feugiat'}
      />
    </p>
    {/*<p className="privacy__contents">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.whoWe'}*/}
    {/*    defaultMessage={'Who are we?'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="privacy__contents">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.whatInformation'}*/}
    {/*    defaultMessage={'What information we collect'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="privacy__contents">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.whatChoices'}*/}
    {/*    defaultMessage={'What choices you have'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="privacy__contents">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.howShare'}*/}
    {/*    defaultMessage={'How and with whom we may share'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="privacy__contents">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.howProtect'}*/}
    {/*    defaultMessage={'How we protect'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="privacy__contents">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.howToContact'}*/}
    {/*    defaultMessage={'How to contact us'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.gritxUnderstands'}*/}
    {/*    defaultMessage={'GritX understands how important the privacy'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleWhatIsGritx'}*/}
    {/*    defaultMessage={'What is Gritx'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.descriptionWhat'}*/}
    {/*    defaultMessage={'GritX is a non-profit website'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleInformationCollected'}*/}
    {/*    defaultMessage={'Information Collected'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.descriptionInformationCollected'}*/}
    {/*    defaultMessage={'We will not share your information'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleWhatChoices'}*/}
    {/*    defaultMessage={'What Choices Do I Have?'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.descriptionWhatChoices'}*/}
    {/*    defaultMessage={'If you do not want your Personal Information'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleHowInformation'}*/}
    {/*    defaultMessage={'How Information Collected?'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.informationCollectTo'}*/}
    {/*    defaultMessage={'We may use information'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<div className="privacy__list">*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.privacy.provideYou'}*/}
    {/*      defaultMessage={'-Provide you with acces'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.privacy.obtainFeedback'}*/}
    {/*      defaultMessage={'-Obtain your feedback on our sites'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.privacy.provideRelevantcontent'}*/}
    {/*      defaultMessage={'-Provide you and people with similar demographic characteristics'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.combineInformation'}*/}
    {/*    defaultMessage={'We may combine Personal and Non-Personal Information'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleCoockies'}*/}
    {/*    defaultMessage={'COOKIES POLICY'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.aboutCookies'}*/}
    {/*    defaultMessage={'About cookies'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.descriptionCookies'}*/}
    {/*    defaultMessage={'This website uses cookies'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.additionalDescriptionCookies'}*/}
    {/*    defaultMessage={'There are two main kinds of cookies'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleWebsiteCookies'}*/}
    {/*    defaultMessage={'Cookies on our website'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.usesCookies'}*/}
    {/*    defaultMessage={'uses the following cookies on this website'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<div className="privacy__list">*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.privacy.collectAggregateInformation'}*/}
    {/*      defaultMessage={'-Collect aggregate information'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.privacy.browserInformation'}*/}
    {/*      defaultMessage={'-Browser-IP-address information'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.privacy.ipAddress'}*/}
    {/*      defaultMessage={'-IP address information'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.privacy.deliverWebContent'}*/}
    {/*      defaultMessage={'-To deliver Web content specific'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleGoogleCookies'}*/}
    {/*    defaultMessage={'Google cookies'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.descriptionGoogleCookies'}*/}
    {/*    defaultMessage={'GritX publishes Google AdSense interest-based advertisements on this website.'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleOtherCookies'}*/}
    {/*    defaultMessage={'[Other] third party cookies'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.descriptionOtherCookies'}*/}
    {/*    defaultMessage={'In addition to our own cookies'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleRefusingCookies'}*/}
    {/*    defaultMessage={'Refusing cookies'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.descriptionRefusingCookies'}*/}
    {/*    defaultMessage={'Most browsers allow you to refuse to accept cookies'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleHowSecure'}*/}
    {/*    defaultMessage={'How Do We Secure and Retain Your Information?'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.descriptionHowSecure'}*/}
    {/*    defaultMessage={'We have put in place technical'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleChangesPrivacy'}*/}
    {/*    defaultMessage={'Changes to This Privacy Policy'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.descriptionChangesPrivacy'}*/}
    {/*    defaultMessage={'We reserve the right to change or modify this Privacy Policy'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="privacy__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.titleContactGritx'}*/}
    {/*    defaultMessage={'Contact GritX'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="privacy__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.privacy.descriptionContactGritx'}*/}
    {/*    defaultMessage={'Please send us an email'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<div className="privacy__list">*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.privacy.contactAttn'}*/}
    {/*      defaultMessage={'Attn: UCSF'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.privacy.contactAdress'}*/}
    {/*      defaultMessage={'Adress'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*  <div className="privacy__list__item">*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.privacy.contactTitleEmail'}*/}
    {/*      defaultMessage={'Email:'}*/}
    {/*    />*/}
    {/*    <div className="privacy__list__item__description">*/}
    {/*      <FormattedMessage*/}
    {/*        id={'gritx.privacy.contactEmail'}*/}
    {/*        defaultMessage={'Email'}*/}
    {/*      />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*  <div className="privacy__list__item">*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.privacy.contactTitlePhone'}*/}
    {/*      defaultMessage={'Phone:'}*/}
    {/*    />*/}
    {/*    <div className="privacy__list__item__description">*/}
    {/*      <FormattedMessage*/}
    {/*        id={'gritx.privacy.contactPhone'}*/}
    {/*        defaultMessage={'Phone'}*/}
    {/*      />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</div>*/}
  </div>;
};
