import React, {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';

import './styles.scss';

export const Term: FunctionComponent = () => {
  return <div className="term">
    <header className="term__title-window">
      <FormattedMessage
        id={'gritx.terms.titleTerms'}
        defaultMessage={'Terms and Conditions'}
      />
    </header>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum1'}
        defaultMessage={'Lorem ipsum'}
      />
    </p>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum2'}
        defaultMessage={'Semper quis'}
      />
    </p>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum3'}
        defaultMessage={'Tellus rutrum'}
      />
    </p>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum4'}
        defaultMessage={'Vestibulum'}
      />
    </p>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum5'}
        defaultMessage={'Vel eros donec ac odio'}
      />
    </p>
    <p className="privacy__description">
      <FormattedMessage
        id={'gritx.loremIpsum6'}
        defaultMessage={'Tempor nec feugiat'}
      />
    </p>
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionTerms'}*/}
    {/*    defaultMessage={'By using this site'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleAgreement'}*/}
    {/*    defaultMessage={'Agreement between User and GritX'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionNonprofitWebsite'}*/}
    {/*    defaultMessage={'GritX is a non-profit website'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionPsychoeducationalWebsite'}*/}
    {/*    defaultMessage={'GritX is a psychoeducational website'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionWeHopeThat'}*/}
    {/*    defaultMessage={'We hope that the information obtained on this site'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionNeitherTheBoard'}*/}
    {/*    defaultMessage={'Neither the Board of Regents nor its officers'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleTermsPrivacy'}*/}
    {/*    defaultMessage={'Privacy'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionTermsPrivacy'}*/}
    {/*    defaultMessage={'Your use of Gritx is subject to Gritx Privacy Policy'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleChildrenPrivacy'}*/}
    {/*    defaultMessage={'Children and Privacy'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionChildrenPrivacy'}*/}
    {/*    defaultMessage={'Gritx is committed to protecting the privacy of youth'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleThirdPartyServices'}*/}
    {/*    defaultMessage={'Links to Third Party Sites/Third Party Services'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionLinks'}*/}
    {/*    defaultMessage={'Gritx contains links to other websites (‘‘Linked Sites”).'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionCertainServices'}*/}
    {/*    defaultMessage={'Certain services made available'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleIntellectualProperty'}*/}
    {/*    defaultMessage={'No Unlawful or Prohibited Use/Intellectual Property'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionGranted'}*/}
    {/*    defaultMessage={'You are granted'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionContentIncluded'}*/}
    {/*    defaultMessage={'All content included as part of the Service'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionYouWillNot'}*/}
    {/*    defaultMessage={'You will not modify, publish'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleInternationalUsers'}*/}
    {/*    defaultMessage={'International Users'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionInternationalUsers'}*/}
    {/*    defaultMessage={'The Service is controlled'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<div className="term__list">*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.terms.collectInformation'}*/}
    {/*      defaultMessage={'-Collect aggregate information'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.terms.browserIP'}*/}
    {/*      defaultMessage={'-Browser IP-address'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.terms.ipAddressInformation'}*/}
    {/*      defaultMessage={'-IP address information'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*  <div>*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.terms.deliverWebContentSpecific'}*/}
    {/*      defaultMessage={'-To deliver Web content'}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleIndemnification'}*/}
    {/*    defaultMessage={'Indemnification'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionIndemnification'}*/}
    {/*    defaultMessage={'(Waiting for the UCSF indemnification language)'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleLiability'}*/}
    {/*    defaultMessage={'Liability of Gritx and Its Licensors'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionYourRisk'}*/}
    {/*    defaultMessage={'The use of the Gritx Site and the Content is at your own risk'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionUseGritx'}*/}
    {/*    defaultMessage={'The accuracy, reliability, completeness'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionPartiesMentioned'}*/}
    {/*    defaultMessage={'In no event shall Gritx'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleAccessRestriction'}*/}
    {/*    defaultMessage={'Termination/Access Restriction'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionUCSFReserves'}*/}
    {/*    defaultMessage={'UCSF reserves the right'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionRelationship'}*/}
    {/*    defaultMessage={'You agree that no joint venture'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionUnlessOtherwise'}*/}
    {/*    defaultMessage={'Unless otherwise specified agreement'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleChangesToTerms'}*/}
    {/*    defaultMessage={'Changes to Terms'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionChangesToTerms'}*/}
    {/*    defaultMessage={'Gritx may revise and update these Terms and Conditions'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleUserSubmissions'}*/}
    {/*    defaultMessage={'User Submissions'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionPersonalInformation'}*/}
    {/*    defaultMessage={'The personal information you submit to Gritx'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionSiteContains'}*/}
    {/*    defaultMessage={'The Site contains functionality'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionSendTransmit'}*/}
    {/*    defaultMessage={'If you make any such submission you agree'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionPublicArea'}*/}
    {/*    defaultMessage={'If you make any submission to a Public Area'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionItsLicensors'}*/}
    {/*    defaultMessage={'Gritx or its licensors'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleGeneral'}*/}
    {/*    defaultMessage={'General'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionGeneral'}*/}
    {/*    defaultMessage={'Gritx is based in San Francisco, California'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleTermination'}*/}
    {/*    defaultMessage={'Termination/Access Restriction'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionTermination'}*/}
    {/*    defaultMessage={'In an effort to protect the rights of copyright owners'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleCompleteAgreement'}*/}
    {/*    defaultMessage={'Complete Agreement'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionCompleteAgreement'}*/}
    {/*    defaultMessage={'Except as expressly provided in a particular legal notice on the Gritx Site'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<h4 className="term__title">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.titleContactUs'}*/}
    {/*    defaultMessage={'Contact us'}*/}
    {/*  />*/}
    {/*</h4>*/}
    {/*<p className="term__description">*/}
    {/*  <FormattedMessage*/}
    {/*    id={'gritx.terms.descriptionContactUs'}*/}
    {/*    defaultMessage={'Gritx welcomes your questions or comments regarding the Terms:'}*/}
    {/*  />*/}
    {/*</p>*/}
    {/*<div className="term__list">*/}
    {/*  <div className="term__list__item">*/}
    {/*    <div>*/}
    {/*      <FormattedMessage*/}
    {/*        id={'gritx.terms..titleAttn'}*/}
    {/*        defaultMessage={'Attn:'}*/}
    {/*      />*/}
    {/*    </div>*/}
    {/*    <div className="term__list__item__description-black">*/}
    {/*      <FormattedMessage*/}
    {/*        id={'gritx.terms.descriptionAddress'}*/}
    {/*        defaultMessage={'Adress'}*/}
    {/*      />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*  <div className="term__list__item">*/}
    {/*    <FormattedMessage*/}
    {/*      id={'gritx.terms.titleEmailAddress'}*/}
    {/*      defaultMessage={'Email:'}*/}
    {/*    />*/}
    {/*    <div className="term__list__item__description">*/}
    {/*      <FormattedMessage*/}
    {/*        id={'gritx.terms.descriptionEmailAddress'}*/}
    {/*        defaultMessage={'Email'}*/}
    {/*      />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</div>*/}
  </div>;
};
